<template>
  <div class="service-setting">
    <PageTitle title="抽獎內容設定" btn="新增抽獎" @btnClick="$router.push('/lottery-create')" />

    <section>
      <el-table
        v-loading="loading"
        :data="memberGameList"
        empty-text="暫無數據"
      >
        <EmptyBlock slot="empty" />
        <!-- <el-table-column
          prop="name"
          label="頁面圖片"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <img
              style="margin: auto;"
              width="90"
              :src="imgSource(scope.row.Image)"
              alt=""
            />
          </template>
        </el-table-column> -->
        <el-table-column prop="name" label="抽獎名稱" align="center" />
        <el-table-column label="抽獎方式" align="center">
          <template slot-scope="scope">
            {{ scope.row.MemberGameMode ?scope.row.MemberGameMode.name : '' }}
          </template>
        </el-table-column>
        <el-table-column prop="enable" label="狀態" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.enable)">
              {{
                statusTrans(scope.row.enable, scope.row.startAt)
              }}
            </Tag>
          </template>
        </el-table-column>

        <el-table-column
          prop="bookingTime"
          label="開始時間"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              {{ dateFormat(scope.row.startAt) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="showTime"
          label="結束時間"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              {{ dateFormat(scope.row.endAt) }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="name"
          label="操作"
          fixed="right"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <TableEditBtnGroup
              editBtn="獎項管理"
              @edit="manageReward($event, scope.row)"
              @delete="deleteDialog=true, selectRow=scope.row"
            />
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="memberGameListCount"
        @pageChange="refresh"
      />
    </section>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteMemberGame(), (deleteDialog = false)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// APIs
import {
  GetMemberGame,
  DeleteMemberGame,
  GetMemberGameCount,
} from '@/api/lottery/memberGame'
import dayjs from 'dayjs'
import DeleteDialog from '@/components/Dialog/DeleteDialog'

// Utils
import { dialogTitle } from '@/utils/dialog'
import { imgSrc } from '@/utils/helper'

import tableMixin from '@/mixin/table'
import dialogMixin from '@/mixin/dialog'
import imageMixin from '@/mixin/image'

// // import * as cssvars from '@/styles/ohbot/_variables.scss'

export default {
  name: 'ServiceSetting',
  components: {
    DeleteDialog,
  },
  mixins: [tableMixin, dialogMixin, imageMixin],
  computed: {
    ...mapGetters(['shop']),

    dialogTitle () {
      return dialogTitle(this.dialogType, {
        create: '新增服務項目',
        update: '更新服務項目',
      })
    },

    categoryList () {
      const list = []
      this.formData.serviceCategorys.forEach((item) => {
        list.push(item.id)
      })
      return list
    },

    serviceUnitList () {
      const list = []
      this.formData.serviceUnits.forEach((item) => {
        list.push(item.id)
      })
      return list
    },
  },
  data: () => ({
    memberGameList: [],
    memberGameListCount: 0,
    loading: false,
  }),

  async mounted () {
    await this.refresh()
  },

  methods: {
    manageReward (e, row) {
      console.log(row)
      this.$router.push(`/reward-manage/${row.LotteryId}?id=${row.id}`)
    },
    tagType (val) {
      let type = 'info'
      if (val) type = 'action'
      // if (!val) type = 'info'
      return type
    },
    statusTrans (enable, startAt) {
      const noStart = dayjs(startAt).isAfter(dayjs(new Date()))
      if (noStart) return '未進行'
      if (enable && !noStart) return '進行中'
      if (!enable) return '已結束'
    },
    imgSource (image) {
      return imgSrc(this.imgSize, image)
    },

    async refresh () {
      this.loading = true
      await this.getMemberGame()
      await this.getMemberGameCount()
      this.loading = false
    },

    //= > 取得會員遊戲
    async getMemberGame () {
      const startIndex = this.pageStartIndex
      const limit = this.tableOptions.pageLimit
      const [res, err] = await GetMemberGame({
        shopId: this.shop,
        start: startIndex,
        limit,
        name: this.nameSearch === '' ? undefined : this.nameSearch,
      })
      if (err) return this.$message.error(err)
      this.memberGameList = res
    },

    //= > 刪除會員遊戲
    async deleteMemberGame () {
      const [res, err] = await DeleteMemberGame({
        shopId: this.shop,
        id: this.selectRow.id,
      })
      if (err) return this.$message.error(err)
      this.memberGameList = res
      this.refresh()
    },
    //= > 取得服務
    async getMemberGameCount () {
      const [res, err] = await GetMemberGameCount({
        shopId: this.shop,
      })
      if (err) return this.$message.error(err)
      this.memberGameListCount = res.count
    },
  },
}
</script>

<style scoped lang="postcss">
.head-block {
  @apply flex items-center justify-between mb-[24px];
}
.test {
  overflow: auto;
}
</style>
